import Request from "@/network/HttpRequest";
import { Response } from "../types/check";
type param = {
  avatar: string;
  channelId: number;
  cityCode: string;
  cityName: string;
  gender: number;
  mobile: string;
  nickName: string;
  purchaseBudgets: string;
  purchaseTime: string;
};
// 判断渠道是否有效
export function chanelCheck(channelId: string): Promise<Response<any>> {
  return Request.get(
    `/yiautos-scrm-clue/inquiry-channel-client/check-inquiry-channel/?channelId=${channelId}`
  );
}
// 询价记录
export function getInquiryList(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/list-inquiry-info",
    method: "get",
    params: data,
  });
}
// 发送手机验证码
export function sendcode(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/send-valid-code",
    method: "get",
    params: data,
  });
}
// 校验手机验证码
export function verifycode(params: any): Promise<Response<any>> {
  return Request.get("/yiautos-scrm-clue/client-inquiry-info/valid-code", {
    params,
  });
}
// 获取报价
export function getQuoteResult(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/get-quote",
    method: "post",
    data,
  });
}
// 获取门店接口
export function getStoreList(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/get-store-by-city-code",
    method: "get",
    params: data,
  });
}
//点赞、踩接口
export function giveThumbUp(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/update-thumb-up",
    method: "post",
    data,
  });
}
//获取询价主页增加轮播用户咨询报价列表接口
export function getQuoteList(data: any) {
  return Request({
    url: "/yiautos-scrm-clue/client-inquiry-info/list-page-channel-quotes",
    method: "post",
    data,
  });
}
