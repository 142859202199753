
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { loading, getQueryString } from "@/utils/Common";
import * as service from "../../api/inquiry";
import { Response } from "../../types/check";
import { setChannelId, getChannelId } from "@/utils/StorageHelper.ts";
import { useStore } from "vuex";
export default defineComponent({
  name: "inquiryIndex",
  setup() {
    const store = useStore();
    // 动态设置keepalive
    store.commit("ClearKeep");
    const isReady = ref(true);
    const enable = ref(true);
    const route = useRoute();
    const router = useRouter();
    const channelId = ref("");
    const activeIndex = ref(0);
    const listData = ref([
      {
        avator:
          "https://websitedn.yiautos.com/channel_quotation/v4/default-avator.png",
        mobile: "",
        modelsName: "阿斯顿·马丁 阿斯顿·马丁DB11 2019款 5.2T 自动 V12 Coupe",
        nickName: "一二三四五六七八九十",
        seriesName: "阿斯顿·罗密欧",
      },
    ]);
    // 判断渠道是否有效
    const isChannelValid = loading(async () => {
      try {
        const res: Response<any> = await service.chanelCheck(channelId.value);
        if (res.code !== 200) {
          throw new Error(res.msg);
        }
        enable.value = res.data;
        if (enable.value) {
          getQuoteList();
        }
      } catch (error) {
        Toast(error.message);
      }
    });
    //去询价
    function inquiryContent() {
      // @ts-ignore
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "秒询底价",
      });
      router.push({
        path: "/inquiry-content",
        query: { channelId: channelId.value },
      });
    }
    //询报价记录
    async function getQuoteList() {
      try {
        const page = {
          size: "20",
          current: "1",
        };
        const res = await service.getQuoteList({ page });
        listData.value = res.data.records || [];
      } catch (error) {
        Toast(error.message);
      }
    }
    function onChange(e: any) {
      activeIndex.value = e;
    }
    onMounted(() => {
      //todo channelId 修改
      if (route.query.channelId) {
        const id = route.query.channelId;
        setChannelId(id);
        channelId.value = id as string;
        isChannelValid();
      } else {
        const id = getChannelId();
        channelId.value = id as string;
        isChannelValid();
      }
    });
    return {
      isReady,
      enable,
      channelId,
      isChannelValid,
      inquiryContent,
      listData,
      activeIndex,
      onChange,
    };
  },
});
